import React from "react";
import Image from "next/image";
import clsx from "clsx";

// components
import { Container } from "@/components/Container";
import { Button } from "@/components/Button";
import { PayAttention } from "@/components/PayAttention";
// stores
import { useDispatch } from "react-redux";
import { handleShowProductModal } from "@/stores/modals";

// styles
import { useTranslation } from "next-i18next";

import Brand from "@/public/home/brand.webp";

export const Banner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("home");

  return (
    <div
      className={clsx(
        "w-full px-[1.5rem] bg-secondary relative overflow-hidden"
      )}
    >
      <div className="absolute top-[-10%] left-[-30%] z-[1] w-[50%] h-[50%] glow"></div>
      <Container className="z-10 relative">
        <div className="flex flex-col md:flex-row min-h-[40rem] lg:items-center lg:min-h-[70rem] lg:max-h-[70rem] relative">
          <div
            className={clsx(
              `flex flex-col items-start pt-[4rem] lg:justify-center md:w-[48%] md:py-[9.4rem] z-10 relative`
            )}
          >
            <PayAttention>{t("upper_title")}</PayAttention>
            <h1
              className="text-text text-[2.3rem] lg:text-[3.8rem] mb-[1.5rem] font-semibold leading-[1.3]"
              dangerouslySetInnerHTML={{ __html: t("banner_h1") }}
            ></h1>
            <h2
              className="text-text text-bodySmall lg:text-[2rem] mb-[2rem] font-semibold"
              dangerouslySetInnerHTML={{ __html: t("banner_h2") }}
            ></h2>
            <Button
              onClick={() => {
                dispatch(handleShowProductModal());
              }}
              type="button"
              color="primary"
              className="text-bodySmall"
              rounded={true}
            >
              {t("banner_button")}
            </Button>
          </div>
          <div className="relative w-full my-[2rem] md:my-[0] md:absolute md:w-[50%] md:top-[50%] md:translate-y-[-50%] z-0 right-0 h-[300px] md:h-[400px]">
            <Image
              src="https://storage.googleapis.com/mapsmi/public/home/brand.webp"
              alt="MapsMi"
              layout="fill"
              objectFit="contain"
              priority
              sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw"
              className="z-[2] relative"
            />

            <div className="absolute top-[50%] translate-y-[-50%] z-[1] w-full h-full glow"></div>
          </div>{" "}
        </div>
      </Container>
    </div>
  );
};
