import { FC, useEffect, useState } from "react";

import { Slider } from "@/components/Slider";
import { Container } from "@/components/Container";
import { SwiperSlide } from "swiper/react";
import { TopSellCategoriesCard } from "@/components/TopSellCategoriesCard";

import { CartProduct } from "@/components/CartProduct";

import { SliderArrows } from "@/components/Slider/SliderArrows";

import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";

import { useTranslation } from "next-i18next";

// api
import { api } from "@/axios";

export const PopularDesigns: FC = (...props): React.ReactNode => {
  const { t } = useTranslation("home");
  const [popularDesigns, setPopularDesigns] = useState([]);

  useEffect(() => {
    api
      .get(`/popular-wallarts?featured=on_sale`)
      .then(({ data }: any) => {
        setPopularDesigns(data);
      })
      .catch(({ response }) => {});
  }, []);

  return popularDesigns.length ? (
    <div className="">
      <Container>
        <div className="py-[2rem] md:py-[5rem]">
          <div className="flex items-center">
            <div className="flex relative w-full items-center">
              <div>
                <h3 className="text-body md:text-h4 font-semibold mb-[.5rem] text-text title-a">
                  {t("favorite_title")}
                </h3>
                <h3 className="text-caption md:text-bodySmall text-text">
                  {t("favorite_desc")}
                </h3>
              </div>
            </div>

            {/* <SliderArrows
                nextArrowClasses="swiper-next-top-sell"
                prevArrowClasses="swiper-prev-top-sell"
                wrapperClasses="gap-[1rem]"
                wrapper={false}
              /> */}
          </div>

          <div className="mt-[1rem] md:mt-[2rem] relative">
            <SliderArrows
              nextArrowClasses="swiper-next-popular-sell top-[50%] right-[-10px] lg:right-[-20px] z-10 translate-y-[-50%]"
              prevArrowClasses="swiper-prev-popular-sell top-[50%] left-[-10px] lg:left-[-20px] z-10 translate-y-[-50%]"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            />
            <Slider
              loop={false}
              pagination={{ clickable: false }}
              navigation={{
                nextEl: ".swiper-next-popular-sell",
                prevEl: ".swiper-prev-popular-sell",
              }}
              slidesPerView={5}
              spaceBetween={5}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                },

                768: {
                  slidesPerView: 3.5,
                },

                1024: {
                  slidesPerView: 4,
                },

                1620: {
                  slidesPerView: 5,
                  spaceBetween: 15,
                },
              }}
              modules={[Navigation]}
            >
              {popularDesigns
                .slice(0, 20)
                .map(
                  ({
                    type,
                    updatedAt,
                    createdAt,
                    price,
                    _id,
                    __v,
                    ...props
                  }: any) => {
                    return (
                      <SwiperSlide key={props.name}>
                        <CartProduct
                          id={props.id}
                          name={props.name}
                          price={price}
                          path={props.path}
                          productId={props.productId}
                          {...props}
                        />
                      </SwiperSlide>
                    );
                  }
                )}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};
