import { FC } from "react";
import Link from "next/link";

import { Slider } from "@/components/Slider";
import { Container } from "@/components/Container";
import { SwiperSlide } from "swiper/react";
import { TopSellCategoriesCard } from "@/components/TopSellCategoriesCard";

// icons

import Sports from "@/public/top-selling-categories/sports.png";
import Landmark from "@/public/top-selling-categories/landmark.png";
import Travel from "@/public/top-selling-categories/travel.png";
import Couples from "@/public/top-selling-categories/couples.png";
import Nature from "@/public/top-selling-categories/nature.png";

import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";

import { SliderArrows } from "@/components/Slider/SliderArrows";

// categories

import { CATEGORIES } from "@/constants/wallart-categories";

import { useTranslation } from "next-i18next";

export const TopSellCategories: FC = (): React.ReactNode => {
  const { t } = useTranslation("home");

  const slides = [
    {
      src: Travel.src,
      ...CATEGORIES[1],
    },
    {
      src: Sports.src,
      ...CATEGORIES[4],
    },
    {
      src: Landmark.src,
      ...CATEGORIES[8],
    },
    {
      src: Couples.src,
      ...CATEGORIES[5],
    },
    {
      src: Nature.src,
      ...CATEGORIES[9],
    },
  ];

  return (
    <div className="">
      <Container>
        <div className="py-[2rem] md:py-[5rem]">
          <div className="flex items-center">
            <div className="flex relative w-full items-center">
              <div>
                <h3 className="text-body md:text-h4 font-semibold mb-[.5rem] text-text title-a">
                  {t("top_sale_title")}
                </h3>
                <h3 className="text-caption md:text-bodySmall text-text">
                  {t("top_sale_desc")}
                </h3>
              </div>
            </div>

            {/* <SliderArrows
              nextArrowClasses="swiper-next-top-sell"
              prevArrowClasses="swiper-prev-top-sell"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            /> */}
          </div>

          <div className="mt-[1rem] md:mt-[2rem] relative">
            <SliderArrows
              nextArrowClasses="swiper-next-top-sell top-[50%] right-[-10px] lg:right-[-20px] z-10 translate-y-[-50%]"
              prevArrowClasses="swiper-prev-top-sell top-[50%] left-[-10px] lg:left-[-20px] z-10 translate-y-[-50%]"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            />
            <Slider
              loop={false}
              pagination={false}
              navigation={{
                nextEl: ".swiper-next-top-sell",
                prevEl: ".swiper-prev-top-sell",
              }}
              slidesPerView={5}
              spaceBetween={5}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                },

                530: {
                  slidesPerView: 3,
                },

                980: {
                  slidesPerView: 4,
                },

                1620: {
                  slidesPerView: 5,
                },
              }}
            >
              {slides.map(({ src, title, link, translate_key }, idx) => {
                return (
                  <SwiperSlide key={link}>
                    <Link href={link}>
                      <TopSellCategoriesCard
                        title={t(translate_key)}
                        src={src}
                        className="w-full bg-secondary"
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Slider>
          </div>

          {/* <Slider
            className="mt-[2rem]"
            loop={false}
            slidesPerView={4}
            spaceBetween={20}
            pagination={{ clickable: false }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },

              530: {
                slidesPerView: 2,
                spaceBetween: 5,
              },

              980: {
                slidesPerView: 3,
                spaceBetween: 10,
              },

              1620: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
          >
            {slides.map(
              ({ image, imageAlt, id, title, description, disabled }) => {
                return <SwiperSlide key={id}></SwiperSlide>;
              }
            )}
          </Slider> */}
        </div>
      </Container>
    </div>
  );
};
