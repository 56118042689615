import { FC } from "react";

import { Slider } from "@/components/Slider";
import { Container } from "@/components/Container";
import { SwiperSlide } from "swiper/react";
import { TopSellCategoriesCard } from "@/components/TopSellCategoriesCard";

import Baseball from "@/public/top-selling-categories/baseball.png";
import Basketball from "@/public/top-selling-categories/basketball.png";
import Cyc from "@/public/top-selling-categories/cycling.png";
import Football from "@/public/top-selling-categories/footbal.png";
import Soccer from "@/public/top-selling-categories/soccer.png";
import Triat from "@/public/top-selling-categories/triat.png";

import { SliderArrows } from "@/components/Slider/SliderArrows";

import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";

import { TOP_SELL_SPORTS } from "@/constants/wallart-categories";
import Link from "next/link";

import { useTranslation } from "next-i18next";

export const TopSellSports: FC = (): React.ReactNode => {
  const { t } = useTranslation("home");

  const slides = [
    {
      src: Baseball.src,
      ...TOP_SELL_SPORTS[0],
    },
    {
      src: Basketball.src,
      ...TOP_SELL_SPORTS[1],
    },
    {
      src: Cyc.src,
      ...TOP_SELL_SPORTS[2],
    },
    {
      src: Football.src,
      ...TOP_SELL_SPORTS[3],
    },
    {
      src: Soccer.src,
      ...TOP_SELL_SPORTS[4],
    },
    {
      src: Triat.src,
      ...TOP_SELL_SPORTS[5],
    },
  ];

  return (
    <div className="">
      <Container>
        <div className="py-[2rem] md:py-[5rem]">
          <div className="flex">
            <div>
              <h3 className="text-body md:text-h4 font-semibold mb-[.5rem] text-text title-a">
                {t("sports_title")}
              </h3>
              <h3 className="text-caption md:text-bodySmall text-text">
                {t("sports_desc")}
              </h3>
            </div>
          </div>

          <div className="mt-[1rem] md:mt-[2rem] relative">
            <SliderArrows
              nextArrowClasses="swiper-next-top-sell-sport top-[50%] right-[-10px] lg:right-[-20px] z-10 translate-y-[-50%]"
              prevArrowClasses="swiper-prev-top-sell-sport top-[50%] left-[-10px] lg:left-[-20px] z-10 translate-y-[-50%]"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            />
            <Slider
              loop={false}
              pagination={{ clickable: false }}
              navigation={{
                nextEl: ".swiper-next-top-sell-sport",
                prevEl: ".swiper-prev-top-sell-sport",
              }}
              slidesPerView={5}
              spaceBetween={5}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                },

                530: {
                  slidesPerView: 3,
                },

                980: {
                  slidesPerView: 4,
                },

                1620: {
                  slidesPerView: 5,
                },
              }}
              modules={[Navigation]}
            >
              {slides.map(({ src, title, link, translate_key }, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <Link href={link}>
                      <TopSellCategoriesCard
                        title={t(translate_key)}
                        src={src}
                        className="w-full bg-secondary"
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Slider>
          </div>

          {/* <Slider
            className="mt-[2rem]"
            loop={false}
            slidesPerView={4}
            spaceBetween={20}
            pagination={{ clickable: false }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },

              530: {
                slidesPerView: 2,
                spaceBetween: 5,
              },

              980: {
                slidesPerView: 3,
                spaceBetween: 10,
              },

              1620: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
          >
            {slides.map(
              ({ image, imageAlt, id, title, description, disabled }) => {
                return <SwiperSlide key={id}></SwiperSlide>;
              }
            )}
          </Slider> */}
        </div>
      </Container>
    </div>
  );
};
