import clsx from "clsx";

interface IPayAttention {
  children?: string | React.ReactElement;
  classNames?: string;
}

export const PayAttention = ({ children, classNames }: IPayAttention) => {
  return (
    <div
      className={clsx(
        "relative inline-block  bg-contain bg-no-repeat bg-center text-text font-semibold text-xl mb-[2rem] px-[1rem] py-[0.5rem] bg-hero-pattern",
        classNames
      )}
    >
      <span>{children}</span>
    </div>
  );
};
