import { Container } from "@/components/Container";
import { Button } from "@/components/Button";

import Image from "next/image";

import DiscountPreview from "@/public/home/discount.webp";

import { PayAttention } from "@/components/PayAttention";

// stores
import { useDispatch } from "react-redux";
import { handleShowProductModal } from "@/stores/modals";

import { useTranslation } from "next-i18next";

export const Discount = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("home");

  return (
    <div className="relative bg-secondary overflow-hidden">
      <div className="absolute w-[60rem] h-[30rem] bottom-0 left-[20%] z-[1] glow"></div>
      <div className="absolute w-[50rem] h-[30rem] top-0 right-[25%] z-[1] glow"></div>
      <div className="bg-button w-full h-[18rem] absolute top-[50%] translate-y-[-50%] z-[5] hidden sm:block"></div>

      <Container className="z-[10] relative flex">
        <div className="flex items-center w-full py-[2rem] md:py-[4rem] min-h-[400px] lg:min-h-[600px]">
          <div className="absolute w-full h-full left-0 top-[-5rem] xs:top-[auto] md:left-[auto] md:w-[60%] md:h-[80%] md:top-[5%]">
            <Image
              src={DiscountPreview.src}
              alt="disc"
              layout="fill"
              objectFit="contain"
              className="rounded-lg overflow-hidden"
            />
          </div>
          <div className="text-center ml-auto bg-primary absolute rounded-3xl flex flex-col items-center justify-center h-[55%] w-[36rem] bottom-[2%] translate-x-[-50%] left-[50%] md:left-[auto] md:translate-x-0 md:bottom-[8%] md:w-[35rem] md:h-[69%] md:right-[10%] md:lg:right-[20%] lg:w-[49rem] lg:h-[55%]">
            <PayAttention>{t("discount_subtitle")}</PayAttention>
            <h4 className="text-[2rem] md:text-[3.2rem] font-semibold text-text leading-[1] mb-[1rem] md:mb-[2rem] w-[60%]">
              {t("discount_title")}
            </h4>
            <p className="text-[1.5rem] text-text font-semibold w-[60%]">
              {t("discount_desc")}
            </p>

            <Button
              type="button"
              color="secondary"
              className="text-caption mt-[2rem]"
              rounded={true}
              onClick={() => {
                dispatch(handleShowProductModal());
              }}
            >
              {t("discount_button")}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
