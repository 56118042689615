// components
import { Slider } from "@/components/Slider";
import { Container } from "@/components/Container";
import { Button } from "@/components/Button";

import { SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
// components
import { PreviewWallartCard } from "@/components/PreviewWallartCard";

import streetmap_0 from "@/public/wallart-preview/streetmap.jpg";
import starmap_0 from "@/public/wallart-preview/starmap_0.jpg";
import lineart_0 from "@/public/wallart-preview/lineart_0.jpg";
import zodiac_0 from "@/public/wallart-preview/zodiac_1.jpg";

import { SliderArrows } from "@/components/Slider/SliderArrows";

import { productRoutes } from "@/constants/routers";

import { useTranslation } from "next-i18next";
export const OurPosters = () => {
  const { t } = useTranslation("home");

  const slides = [
    {
      id: 1,
      title: "Street Map",
      description: (
        <p className="text-captionSmall mt-[.5rem] leading-[2rem] line-clamp-2">
          {t("streetmap")}
        </p>
      ),
      image: "https://storage.googleapis.com/mapsmi/public/home/streetmap.jpg",
      href: productRoutes.STREETMAP,
    },
    {
      id: 2,
      title: "Line Art",
      description: (
        <p className="text-text text-captionSmall mt-[.5rem] leading-[2rem] line-clamp-2">
          {t("lineart")}
        </p>
      ),
      image: "https://storage.googleapis.com/mapsmi/public/home/lineart_0.jpg",
      href: productRoutes.LINEART,
    },
    {
      id: 3,
      title: "Star Map",
      description: (
        <p className="text-text text-captionSmall mt-[.5rem] leading-[2rem] line-clamp-2">
          {t("starmap")}
        </p>
      ),
      image: "https://storage.googleapis.com/mapsmi/public/home/starmap_0.jpg",
      href: productRoutes.STARMAP,
    },
    {
      id: 4,
      title: "Zodiac",
      description: (
        <p className="text-text text-captionSmall mt-[.5rem] leading-[2rem] line-clamp-2">
          {t("zodiac")}
        </p>
      ),
      image: "https://storage.googleapis.com/mapsmi/public/home/zodiac_1.jpg",
      href: productRoutes.ZODIAC,
    },
  ];

  return (
    <div className="bg-primary">
      <Container>
        <div className="py-[2rem] md:py-[5rem]">
          <div className="flex flex-col">
            <h3 className="text-body md:text-h4 font-semibold mb-[.5rem] text-text title-a">
              {t("our_h1")}
            </h3>
            <h3 className="text-caption md:text-bodySmall text-text">
              {t("our_desc")}
            </h3>
          </div>

          <div className="mt-[1rem] md:mt-[2rem] relative">
            <SliderArrows
              nextArrowClasses="swiper-next-shop top-[50%] right-[-10px] lg:right-[-20px] z-10 translate-y-[-200%]"
              prevArrowClasses="swiper-prev-shop top-[50%] left-[-10px] lg:left-[-20px] z-10 translate-y-[-200%]"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            />
            <Slider
              loop={false}
              navigation={{
                nextEl: ".swiper-next-shop",
                prevEl: ".swiper-prev-shop",
              }}
              slidesPerView={4}
              spaceBetween={5}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                  // centeredSlides: true,
                },

                420: {
                  slidesPerView: 2.5,
                },

                980: {
                  slidesPerView: 3.5,
                },

                1620: {
                  slidesPerView: 4,
                },
              }}
              modules={[Navigation]}
            >
              {slides.map(({ image, id, title, description, href }) => {
                return (
                  <SwiperSlide key={id}>
                    <PreviewWallartCard
                      title={title}
                      description={description}
                      image={image}
                      // disabled={disabled}
                      href={href}
                      className="h-full shadow-md"
                      id={id}
                    />
                  </SwiperSlide>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </div>
  );
};
