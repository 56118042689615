import Head from "next/head";
import { NextSeo } from "next-seo";

export default function MetaTags({
  metaTile,
  metaDescription,
  metaOgTitle,
  metaOgDescription,
}) {
  return (
    <NextSeo
      title={metaTile}
      description={metaDescription}
      canonical="https://mapsmi.com/"
      openGraph={{
        url: "https://mapsmi.com/",
        title: metaOgTitle,
        description: metaOgDescription,
        images: [
          {
            url: "https://storage.googleapis.com/mapsmi/public/home/brand.webp",
            width: 800,
            height: 600,
            alt: "MapsMi",
            type: "image/webp",
          },
        ],
        siteName: "MapsMi",
      }}
      // twitter={{
      //   handle: "@handle",
      //   site: "@site",
      //   cardType: "summary_large_image",
      // }}
    />
    // <Head>
    //   <title>{metaTile}</title>
    //   <meta name="description" content={metaDescription} />
    //   <meta property="og:title" content={metaOgTitle} />
    //   <meta property="og:description" content={metaOgDescription} />
    // </Head>
  );
}
