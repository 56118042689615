import { Swiper } from "swiper/react";

import "swiper/swiper-bundle.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const Slider = ({ children, ...props }) => {
  return <Swiper {...props}>{children}</Swiper>;
};
