import Arrow from "@/public/slider/arrow.svg";
import { FC } from "react";

import clsx from "clsx";

interface ISliderArrows {
  wrapperClasses?: string;
  arrowClasses?: string;
  prevArrowClasses?: string;
  nextArrowClasses?: string;
  wrapper?: boolean;
  fillArrow?: string;
  arrowSize?: string;
}

export const SliderArrows: FC<ISliderArrows> = ({
  wrapperClasses,
  arrowClasses,
  prevArrowClasses,
  nextArrowClasses,
  wrapper = true,
  fillArrow = "#fff",
  arrowSize = "w-[30px] h-[30px] md:w-[40px] md:h-[40px]",
}) => {
  return wrapper ? (
    <div className={clsx("flex z-10", wrapperClasses)}>
      <button
        className={clsx(
          "flex items-center justify-center rounded-full z-10 bg-button",
          arrowSize,
          prevArrowClasses
        )}
      >
        <Arrow
          width={14}
          className="rotate-180 relative left-[-1px]"
          fill={fillArrow}
        />
      </button>
      <button
        className={clsx(
          "flex items-center justify-center bg-button rounded-full",
          arrowSize,
          nextArrowClasses
        )}
      >
        <Arrow width={14} fill={fillArrow} className="relative right-[-1px]" />
      </button>
    </div>
  ) : (
    <>
      <button
        className={clsx(
          "absolute flex items-center justify-center rounded-full z-10 bg-button",
          arrowSize,
          prevArrowClasses
        )}
      >
        <Arrow
          width={14}
          className="rotate-180 relative left-[-1px]"
          fill={fillArrow}
        />
      </button>
      <button
        className={clsx(
          "absolute flex items-center justify-center bg-button rounded-full",
          arrowSize,
          nextArrowClasses
        )}
      >
        <Arrow width={14} fill={fillArrow} className="relative right-[-1px]" />
      </button>
    </>
  );
};
